.btn {
  &-cmn {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    height: 60px;
    background: 0;
    padding: 0;
    border: 1px solid #666;
    color: #FFF;
    font-size: 2.0rem;
    font-family: $font-amiri;
    text-align: center;
    transition: $transition;
    cursor: pointer;
    @include mq() {
      max-width: 66.66vw;
      height: 16.66vw;
      font-size: 3.2rem;
    }
    &:hover {
      background: #666;
    }
    &__ico-pdf {
      position: absolute;
      right: 30px;
      top: 50%;
      width: 20px;
      height: 22px;
      margin-top: -11px;
      fill: #FFF;
      @include mq() {
        right: 4vw;
        width: 5.06vw;
        height: 5.33vw;
        margin-top: -2.665vw;
      }
    }
    &__ico-arrow {
      position: absolute;
      right: 40px;
      top: 21px;
      width: 24px;
      height: 7px;
      background: url(../img/common/ico_btn_arrow.png) center center/ 100% 100% no-repeat;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      @include mq() {
        right: 4vw;
        top: 4.53vw;
        width: 3.86vw;
        height: 1.3vw;
      }
      &--left {
        @extend .btn-cmn__ico-arrow;
        right: auto;
        left: 30px;
        background-image: url(../img/common/ico_btn_arrow_left.png);
      }
    }
    &__inner {
      display: block;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  &-more {
    margin-top: 25px;
    text-align: right;
    @include mq() {
      margin-top: 4vw;
    }
  }
  &-txt-link {
    @extend .txt-link;
    font-size: 1.8rem;
    font-family: $font-amiri;
    @include mq() {
      font-size: 3.0rem;
    }
    &__arrow {
      display: inline-block;
      width: 6px;
      height: 10px;
      background: url(../img/common/ico_arrow_right.png) center center / cover no-repeat;
      vertical-align: middle;
      transition: $transition;
      @at-root a:hover & {
        transform: translateX(50%);
      }
    }
  }
}