.sidebar {
  &-ttl {
    margin-bottom: 20px;
    font-family: $font-amiri;
    font-size: 2.0rem;
    @include mq() {
      margin-bottom: 8vw;
      font-size: 5.0rem;
    }
  }
  .sidebar-list {
    font-size: 1.2rem;
    @include mq() {
      font-size: 2.4rem;
    }
    .sidebar-list__en {
      font-size: 1.4rem;
      @include mq() {
        font-size: 2.6rem;
      }
    }
  }
  .sidebar-archive {
    li {
      @extend .list-cmn__item;
    }
    a {
      @extend .list-cmn__link;
      @extend .txt-link;
    }
  }
}