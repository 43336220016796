.list {
  &-cmn {
    &__item {
      padding-bottom: 8px;
      border-bottom: 1px solid #777;
      @include mq() {
        padding-bottom: 2.66vw;
        font-size: 2.4rem;
      }
      & + & {
        margin-top: 20px;
        @include mq() {
          margin-top: 4.5vw;
        }
      }
    }
    &__date {
      margin-bottom: 3px;
      @include mq() {
        margin-bottom: 1.33vw;
      }
    }
    &__ttl {
      padding: 0 5px;
      @include mq() {
        padding: 0 .933vw;
      }
    }
    &__en {
      font-size: 1.8rem;
      font-family: $font-amiri;
      @include mq() {
        margin-top: .7rem;
        font-size: 2.6rem;
      }
    }
    p {
      transition: $transition;
      @at-root .list-cmn__link:hover p {
        opacity: .7;
      }
    }
  }
  &-txt-link {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-right: 3vw;
      @include mq() {
        margin-right: 25px;
      }
    }
  }
}

.bread-list {
  display: none;
  @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding: 5px 5px 0 10px;
    list-style-type: none;
    font-size: 1.2rem;
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
    li + li:before {
      margin: 0 10px;
      font-size: 1.0rem;
      content: '>';
    }
    &__ico {
      margin-top: -2px;
      fill: #686868;
      &--white {
        fill: #FFF;
      }
    }
  }
}