.profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  @include mq() {
    display: block;
  }
  &__img {
    flex-basis: 33.33%;
    max-width: 33.33%;
    @include mq() {
      width: 85.33vw;
      max-width: none;
      margin: 0 auto;
    }
  }
  &-txt {
    flex-basis: 58.34%;
    max-width: 58.34%;
    margin-top: -1em;
    line-height: 2;
    @include mq() {
      max-width: none;
      margin-top: 8.4vw;
      line-height: 1.75;
    }
    &__ttl {
      margin-bottom: 25px;
      font-size: 3.0rem;
      @include mq() {
        margin-bottom: 2vw;
        font-size: 5.0rem;
        line-height: 2;
      }
    }
    &__en {
      font-size: 1.8rem;
      line-height: 1.5;
      @include mq() {
        font-size: 2.8rem;
      }
    }
    &__block {
      & + & {
        margin-top: 55px;
        @include mq() {
          margin-top: 12vw;
        }
      }
    }
    p + p {
      margin-top: 32px;
      @include mq() {
        margin-top: 6vw;
      }
    }
  }
}