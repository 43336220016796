ul.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 90px;
  align-items: center;
  font-family: $font-amiri;
  text-align: center;
  @include mq() {
    width: 88vw;
    margin: 12vw auto 0;
  }
  a,
  span {
    display: block;
    border: 1px solid #FFF;
    color: #FFF;
    &.current {
      background: #FFF;
      color: #000003;
    }
  }
  a {
    transition: $transition;
    &:hover {
      opacity: .6;
    }
  }
  .page-numbers {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0 15px;
    padding-top: .2em;
    font-size: 2.0rem;
    @include mq() {
      width: 8vw;
      height: 8vw;
      margin: 0 2.66vw;
      font-size: 2.4rem;
    }
  }
  .next,
  .prev {
    width: 90px;
    height: 30px;
    margin: 0 25px;
    font-size: 1.4rem;
    @include mq() {
      width: 13.33vw;
      height: 5.33vw;
      margin: 0 4vw;
      font-size: 2.0rem;
    }
  }
  li:first-child {
    a,
    span {
      margin-left: 0;
    }
  }
  li:last-child {
    a,
    span {
      margin-right: 0;
    }
  }
  @mixin pager-arrow($elm, $rotate, $margin) {
    &:#{$elm} {
      width: 6px;
      height: 6px;
      margin-#{$margin}: 3px;
      transform: rotate($rotate);
      content: '';
      @content;
      @include mq() {
        width: 1vw;
        height: 1vw;
      }
    }
  }
  .prev {
    @include pager-arrow(before, -45deg, right) {
      border-left: 1px solid #FFF;
      border-top: 1px solid #FFF;
    }
  }
  .next {
    @include pager-arrow(after, 45deg, left) {
      border-right: 1px solid #FFF;
      border-top: 1px solid #FFF;
    }
  }
}