.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  border-bottom: 1px solid #3f3f3f;
  z-index: 100;
  @include mq() {
    display: block;
    padding: 4.266vw 0 3.2vw;
    border-bottom-color: #FFF;
  }
  &-logo {
    @include mq() {
      width: 39.06vw;
      margin: 0 auto;
    }
  }
  &-trigger {
    display: none;
    @include mq() {
      display: block;
      position: absolute;
      top: 5.46vw;
      right: 6.66vw;
      width: 6.66vw;
      height: 5.2vw;
      cursor: pointer;
      &__item {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: #FFF;
        transition: $transition;
        &:nth-child(1) {
          top: 0;
          @at-root .nav-open & {
            top: 50%;
            margin-top: -1px;
            transform: rotate(45deg);
          }
        }
        &:nth-child(2) {
          top: 50%;
          margin-top: -1px;
          @at-root .nav-open & {
            opacity: 0;
          }
        }
        &:nth-child(3) {
          bottom: 0;
          @at-root .nav-open & {
            top: 50%;
            bottom: auto;
            margin-top: -1px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}