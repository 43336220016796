.form {
  &-intro {
    color: #DDD;
    text-align: center;
    @include mq() {
      text-align: left;
    }
    &--en {
      font-size: 1.8rem;
      @include mq() {
        font-size: 2.8rem;
      }
    }
    &__tel {
      margin-top: 25px;
      font-size: 3.0rem;
      line-height: 1;
      @include mq() {
        margin-top: 6.66vw;
        font-size: 4.0rem;
      }
      &-number {
        font-size: 4.0rem;
        pointer-events: none;
        @include mq() {
          font-size: 6.0rem;
          pointer-events: auto;
        }
      }
    }
  }
  &-finish {
    @include mq() {
      font-size: 2.8rem;
    }
    &__en {
      margin-top: 10px;
    }
    &-contact {
      margin-top: 40px;
      padding: 25px 20px;
      border: 1px solid #FFF;
      text-align: center;
      @include mq() {
        margin-top: 6vw;
        padding: 5vw 4vw 3vw;
        text-align: left;
      }
      &__tel {
        @include mq() {
          font-size: 2.4rem;
        }
        &-number {
          @include mq() {
            font-size: 3.6rem;
          }
        }
      }
      &__mail {
        margin-top: 10px;
        font-size: 3.0rem;
        @include mq() {
          margin-top: 1.2vw;
          font-size: 2.4rem;
        }
        &-address {
          font-size: 4.0rem;
          @include mq() {
            font-size: 3.6rem;
          }
        }
      }
    }
  }
  &-table {
    width: 100%;
    margin: -15px auto 0;
    @include mq() {
      margin-top: 0;
    }
    tr {
      @include mq() {
        display: block;
        margin-top: 4vw;
        & + tr {
          margin-top: 7vw;
        }
      }
    }
    th,
    td {
      padding-top: 50px;
      vertical-align: top;
      @include mq() {
        display: block;
        padding: 0;
        width: 100%;
      }
    }
    th {
      padding-left: 25px;
      font-size: 1.8rem;
      font-family: $font-amiri;
      @at-root table:not(.confirm) th {
        padding-top: 56px;
        @include mq() {
          padding-top: 0;
        }
      }
      @include mq() {
        margin-bottom: 4vw;
        padding: 0;
        font-size: 2.8rem;
      }
      sup {
        font-family: $font-family-default;
        font-size: .8rem;
        @include mq() {
          font-size: 1.4rem;
        }
      }
    }
    td {
      &.confirm {
        @include mq() {
          margin-top: -1.5vw;
          padding-left: 2vw;
        }
      }
    }
  }
  &-input {
    @mixin input-parts {
      width: 100%;
      padding: 0 .5em;
      background: rgba(255,255,255,.1);
      border: 1px solid #FFF;
      color: $font-color-default;
      font-size: 1.6rem;
      @include mq() {
        font-size: 3.2rem;
      }
      &.error {
        background: rgba(153,3,13,.33);
        border-color: #99030d;
      }
    }
    &-txt {
      @include input-parts;
      height: 40px;
      @include mq() {
        height: 8vw;
      }
    }
    &-txtarea {
      @include input-parts;
      padding: {
        top: .5em;
        bottom: .5em;
      };
      height: 150px;
      resize: none;
      @include mq() {
        height: 26.66vw;
      }
    }
  }
  &-btn {
    width: 300px;
    height: 50px;
    margin-top: 80px;
    @include mq() {
      width: 66.66vw;
      height: 11.33vw;
      margin-top: 10.66vw;
    }
  }
  &-btns {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq() {
      display: block;
    }
    &__item {
      margin: 0 40px;
    }
    &__submit {
      order: 1;
    }
    &__back {
      order: 0;
      @include mq() {
        margin-top: -2.66vw;
      }
    }
  }
  &-error {
    margin-top: 15px;
    padding: 4px 0;
    background: rgba(153,3,13,.33);
    @include mq() {
      margin-top: 9.06vw;
      padding: 2.66vw 4vw;
    }
  }
}