.twin-column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  @include mq() {
    display: block;
    padding: 0;
  }
  &__main {
    flex-basis: 75%;
    max-width: 75%;
    padding-right: 50px;
    @include mq() {
      max-width: none;
      padding: 0;
    }
  }
  &__sidebar {
    flex-basis: 25%;
    max-width: 25%;
    @include mq() {
      max-width: none;
      margin-top: 10.3vw;
      padding: 0 4vw;
    }
  }
}