.article {
  &-item {
    padding: 40px 0;
    border-bottom: 1px solid rgba(85,85,85,.5);
    @include mq() {
      padding: 10vw 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    &__inner {
      padding: 0 32px;
      @include mq() {
        padding: 0 6vw;
      }
    }
    &--acordion {
      cursor: pointer;
      transition: $transition;
      &:not(.open):hover {
        background: rgba(255,255,255,.1);
      }
      &.open {
        cursor: default;
      }
    }
  }
  &-header {
    font-size: 2.0rem;
    @include mq() {
      font-size: 3.2rem;
    }
    &__date {
      margin-left: -10px;
      margin-bottom: 8px;
      @include mq() {
        margin-left: -1.33vw;
        margin-bottom: 1.2vw;
      }
    }
    &__ttl {
      &--en {
        font-size: 2.2rem;
        @include mq() {
          font-size: 3.2rem;
        }
      }
    }
    &--trigger {
      position: relative;
      padding-right: 40px;
      transition: $transition;
      &.open {
        cursor: pointer;
      }
      @include mq() {
        padding-right: 6vw;
      }
      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        border-right: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
        transform: rotate(45deg);
        transition: $transition;
        content: '';
        @include mq() {
          width: 3vw;
          height: 3vw;
          margin-top: -1.5vw;
        }
        @at-root .open & {
          transform: rotate(-135deg);
        }
      }
      & + .article-item__main {
        display: none;
      }
    }
  }
  &__main {
    margin-bottom: 35px;
    @include mq() {
      margin-bottom: 6vw;
    }
  }
  &-images {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    margin-top: 65px;
    @include mq() {
      margin-top: 8vw;
    }
    &__item {
      position: relative;
      flex-basis: 282px;
      max-width: 282px;
      padding: 0;
      border: 0;
      background: 0;
      cursor: pointer;
      @include mq() {
        flex-basis: 28.13vw;
        max-width: 28.13vw;
      }
      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        background: url(../img/common/ico_zoom.png) 0 0 / cover no-repeat;
        content: '';
        @include mq() {
          width: 10.13vw;
          height: 10.13vw;
        }
      }
      & + & {
        margin-left: 30px;
        @include mq() {
          margin-left: 4vw;
        }
      }
      &--modal-none {
        @extend .article-images__item;
        &:after {
          content: none;
        }
      }
    }
  }
  &-eyecatch {
    margin-top: 40px;
    text-align: center;
    @include mq() {
      margin-top: 5.33vw;
    }
  }
  &-content {
    margin-top: 30px;
    line-height: 2;
    @include mq() {
      margin-top: 8.66vw;
    }
    &--en {
      @extend .article-content;
      margin-top: 80px;
      font-size: 1.8rem;
      line-height: 1.5;
      @include mq() {
        margin-top: 15vw;
        font-size: 2.6rem;
      }
    }
    a {
      @extend .txt-link;
      @extend .txt-underline;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  &-btn {
    margin-top: 50px;
    @include mq() {
      margin-top: 9vw;
    }
  }
  &-year {
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(85,85,85,.5);
    text-align: center;
    font-family: $font-amiri;
    @include mq() {
      padding-bottom: 6.66vw;
    }
    &__txt {
      display: block;
      font-size: 2.4rem;
      font-weight: bold;
      @include mq() {
        font-size: 3.6rem;
      }
    }
    &__select {
      display: inline-block;
      height: 34px;
      margin: 14px auto 0;
      padding: 0 40px;
      background: transparent url(../img/common/ico_select_arrow.png) 137px center / 8px no-repeat;
      border: 1px solid #FFF;
      color: #FFF;
      font-family: $font-amiri;
      font-size: 1.8rem;
      text-align: center;
      @include mq() {
        height: 6.66vw;
        margin-top: 2.66vw;
        padding: 0 9.33vw;
        background-position: 28.93vw center;
        background-size: 1.73vw;
        font-size: 2.6rem;
      }
      &::-ms-expand {
        display: none;
      }
      option {
        color: #333;
      }
    }
  }
}