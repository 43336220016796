html {
  background: #000;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @include mq() {
    font-size: calc(100vw / 75);
  }
}
body {
  color: $font-color-default;
  font-size: 1.6rem;
  font-family: $font-family-default;
  font-weight: 300;
  line-height: 1.5;
  @include mq() {
    font-size: 2.4rem;
  }
  &.preload * {
    transition: 0s !important;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
}
.alpha-img {
  img {
    transition: $transition;
  }
  &:hover {
    img {
      opacity: .7;
    }
  }
}
input:not([type="radio"]):not([type="checkbox"]),
button,
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

.inner {
  max-width: 1240px;
  margin: 0 auto;
  padding: {
    left: 20px;
    right: 20px;
  };
  @include mq() {
    padding: {
      left: 4vw;
      right: 4vw;
    };
  }
  &--sm {
    @extend .inner;
    max-width: 940px;
  }
}

.only {
  &-pc,
  &-sp {
    display: none;
  }
  @include mq(md) {
    &-pc {
      display: block;
    }
  }
  @include mq() {
    &-sp {
      display: block;
    }
  }
}