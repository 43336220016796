.intro {
  margin-top: 5.2vw;
  text-align: center;
  @include mq() {
    margin-top: 40px;
  }
  &__ttl {
    font-size: 148%;
  }
  &__btn {
    margin-top: 7vw;
    @include mq() {
      margin-top: 30px;
    }
  }
}
.box-menu {
  margin-top: 7vw;
  @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  &__img {
    overflow: hidden;
    img {
      transition: $transition;
      @at-root .box-menu__item:hover & {
        opacity: .8;
        transform: scale(1.1);
      }
    }
  }
  &__item {
    display: block;
    margin-top: 4vw;
    color: #FFF;
    text-align: center;
    @include mq() {
      flex-basis: 48%;
      max-width: 48%;
      margin-top: 30px;
      &:nth-child(2n) {
        margin-left: 4%;
      }
    }
  }
  &__name {
    margin-top: .3vw;
    font-size: 3.6rem;
    @include mq() {
      margin-top: 3px;
      font-size: 2.0rem;
    }
  }
  &__svg {
    margin-top: -1vw;
    margin-left: 1.5vw;
    fill: #FFF;
    @include mq() {
      margin-top: -4px;
      margin-left: 5px;
    }
  }
}
.menu-auction {
  background: #FFF;
  @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  &__head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    background: #efb418;
    justify-content: center;
    align-items: center;
    height: 45vw;
    @include mq() {
      flex-basis: 48%;
      max-width: 48%;
      height: auto;
    }
    img {
      width: 55%;
      @include mq() {
        width: auto;
      }
    }
  }
  &__txt {
    padding: 5vw;
    @include mq() {
      flex-basis: 52%;
      max-width: 52%;
      padding: 30px 40px;
    }
  }
  &__ico {
    margin-left: 1.5vw;
    margin-top: -.8vw;
    @include mq() {
      margin-top: -2px;
      margin-left: 5px;
    }
  }
  &__item {
    & + & {
      margin-top: .85em;
    }
  }
}