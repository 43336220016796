//animation
.fadeIn { opacity: 0;}
.fadeInUp {
  animation-fill-mode:both;
  animation-duration:1.5s;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0);}
}
