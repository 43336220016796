.footer {
  background: rgba(0,0,0,.7);
  &-info {
    padding: 30px 0;
    border-color: rgba(255,255,255,.2);
    border-width: 1px 0;
    border-style: solid;
    @include mq() {
      padding: 5.33vw 0;
    }
  }
  &-bnr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding: 0 10px;
    text-align: center;
    @include mq() {
      display: block;
      padding: 0 10.66vw;
    }
    &__item {
      flex-basis: 23.725%;
      max-width: 23.725%;
      margin-right: 1.7%;
      @include mq() {
        max-width: none;
        margin: 0;
        & + & {
          margin-top: 5.33vw;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-main {
    padding: 40px 0 28px;
    background: rgba(15,15,15,.9);
    text-align: center;
    @include mq() {
      padding: 8vw 0 7.33vw;
    }
    &__logo {
      @include mq() {
        img {
          width: 53.06vw;
          max-width: 398px;
        }
      }
    }
    &__copyright {
      margin-top: 36px;
      font-size: 1.2rem;
      @include mq() {
        margin-top: 6vw;
        font-size: 2.0rem;
      }
    }
  }
}