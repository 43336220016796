.lower {
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/common/bg_lower_main_pc.png) center 60px / cover no-repeat;
    content: '';
    z-index: 0;
    @include mq() {
      background-image: url(../img/common/bg_lower_main_sp.png);
      background-position: 0 0;
      background-size: 100%;
    }
  }
  &-main {
    position: relative;
    &__content {
      position: relative;
      padding-top: 40px;
      background: rgba(0,0,0,.7);
      @include mq() {
        padding-top: 6.66vw;
      }
    }
  }
  &-ttl {
    font-family: $font-amiri;
    font-size: 5.0rem;
    line-height: 1.3;
    text-align: center;
    &__inner {
      position: relative;
      display: block;
      padding: 75px 0 70px;
      @include mq() {
        padding: 10vw 0 9.33vw;
      }
    }
    &__btn {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 183px;
      padding-bottom: 14px;
      border-bottom: 2px solid #FFF;
      color: #FFF;
      font-family: $font-family-default;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: left;
      @include mq() {
        width: 100%;
        padding-bottom: 0;
        bottom: 2.66vw;
        border-bottom: 0;
        font-size: 2.7rem;
        text-align: center;
      }
      &-inner {
        font-weight: normal;
        @include mq() {
          position: relative;
          display: inline-block;
          padding: 0 4.93vw 1vw 0;
          &:before {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #FFF;
            content: '';
          }
        }
        &:after {
          position: absolute;
          right: 0;
          top: 7px;
          width: 20px;
          height: 6px;
          background: url(../img/common/ico_btn_arrow.png) center center / 20px 6px no-repeat;
          transition: $transition;
          content: '';
          @include mq() {
            top: auto;
            bottom: 1.6vw;
            width: 2.66vw;
            height: 1.06vw;
            background-size: 100% 100%;
          }
          @at-root a:hover & {
            transform: translateX(20%);
          }
        }
      }
    }
  }
}