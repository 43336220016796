.gnav {
  @include mq() {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: #000;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @at-root .nav-open & {
      top: 16vw;
      height: calc(100vh - 16vw);
      opacity: 1;
      visibility: visible;
    }
  }
  &-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    @include mq() {
      display: block;
    }
    &__item {
      margin: 0 20px;
      @include mq() {
        margin: 0;
        border-top: 1px solid rgba(153,153,153,.9);
      }
    }
    &__link {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      padding: 0 5px;
      color: #FFF;
      @include mq() {
        height: 18.66vw;
        font-size: 4.0rem;
      }
      &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #FFF;
        opacity: 0;
        visibility: hidden;
        transition: .7s;
        content: '';
        @at-root .gnav-list__link:hover:before {
          opacity: 1;
          visibility: visible;
        }
        @include mq() {
          content: none;
        }
      }
    }
    &__sns {
      display: block;
      @include mq() {
        padding: 6.66vw 0;
        text-align: center;
      }
      img {
        vertical-align: top;
      }
    }
  }
}