
//ブレイクポイントをマップ型で定義
$breakpoints: (
  'sp': 'screen and (max-width: 768px)',//スマホ
  'touch': 'screen and (max-width: 1024px)',//タブレット縦～横
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 769px)',
  'lg': 'screen and (min-width: 1025px)',
  'xl': 'screen and (min-width: 1280px)',
) !default;

$font-color-default: #FFF;

$font-family-default: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
$font-amiri: 'Amiri', serif;

$transition: .3s;


//color
$base-color: #11299b;
$red: #cc0000;