.kv {
  background: url(../img/top/bg_kv_pc.jpg) center center / cover no-repeat;
  text-align: center;
  @include mq() {
    &__img {
      width: 100%;
    }
  }
}

.top {
  &-inner {
    padding: 43px 6.66% 27px;
    @include mq() {
      padding: 6.4vw 4vw 4vw;
    }
  }
  &-schedule {
    position: relative;
    display: table;
    width: 100%;
    margin-top: -40px;
    @include mq() {
      display: block;
      margin-top: 0;
    }
    &__item {
      display: table-cell;
      vertical-align: top;
      @include mq() {
        display: block;
      }
    }
    &__ttl {
      width: 180px;
      @include mq() {
        width: 100%;
      }
    }
  }
  &-info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    @include mq() {
      display: block;
    }
    &__news {
      flex-basis: 47.11%;
      max-width: 47.11%;
      @include mq() {
        max-width: none;
      }
    }
    &__fb {
      flex-basis: 48.07%;
      max-width: 48.07%;
      margin-bottom: 23px;
      @include mq() {
        max-width: 500px;
        margin: 12.93vw auto 2.5vw;
      }
    }
  }
}